
//    Font Family

//$primary-font  : 'Poppins', sans-serif;
$primary-font: 'Noto Sans', sans-serif;
$headings-font: 'Fjalla One', sans-serif;

/*--------------------------- Color variations ----------------------*/

$primary-color	: #e3b5a8;
$title-color	: #222222;
$text-color		: #777777;

$white			: #fff;
$black			: #000;



