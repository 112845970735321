
.navbar-brand img {
  height: 34px !important;
  width: auto !important;
  max-width: inherit;
}

//.project-dropdown-item {
////  margin: 0 !important;
////  padding: 0 !important;
////  padding-top: 0 !important;
////  padding-bottom: 0 !important;
//  cursor: pointer !important;
//}
//
//.project-dropdown-item:hover {
//    cursor: pointer !important;
//}

.post-comments-thread {
}

.badge-light {
  background-color: #dfddd3;
}

.img-zoom {
  cursor: zoom-in;  
}

.footer-logo {
  height: 17px !important;
}

.default-footer {
  background-color: #242422 !important;
}

.author-name {
  font-weight: bold;
  font-size: 1.6em;
  margin-top: 0.5em;
}

.index-hello {
  max-height: 200px;
  background: url('/assets/images/placeholder.jpg');
  padding-top: 8px;
}

.index-hello-content {
  margin-top: 60px;
  margin-bottom: 60px;
}

.footer-copyright {
  position: relative;
  top: 5px;
  display: inline-block;
  font-size: 0.9em;
}

.navbar-nav .nav-link {
  font-family: 'Fjalla One', sans-serif;
  font-size: 1.1em;
}

.navbar-nav .nav-link {
  color: #777;
}

.navbar-nav .nav-link:hover {
  color: #222;
}

.nav-item a i {
  top: 0.6em;
  position: relative;
  display: inline-block;
}

.wrapper {
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.15);
  margin: 40px;
  position: relative;
  background: #f8f6eb;
//  background: #fafafb;
}

body {
  position: relative;
  background: #e3b5a8;
//  background: #e3b5a8 url('/assets/images/background-pattern-chevron-a.png') repeat !important;
}

@media (max-width: 766px) {
  .wrapper {
    box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.15);
    margin: 11px;
  }
}



